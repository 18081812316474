import React from "react";
import Section from "../../containers/Section";
import Badge from "../Badge";
import Brands from "../Brands";
import Header, { HeaderVariant } from "../Header";
import NavigationBarSpacer from "../NavigationBarSpacer";
import { GatsbyImage } from "gatsby-plugin-image";
import HeroCtas from "../HeroCtas";
import GradientTitle from "../GradientTitle";
import Icon, { IconType } from "../Icon";
import cn from "classnames";
import CreateLink from "../ReferralPage/CreateLink";
import { PAGE } from "../../constants/pages";

interface HeroSectionProps {
  title?: string;
  gradientTitle?: string[];
  gradientPosition?: number;
  gradientTitleVariant?: any;
  lineBreak?: boolean;
  color?: string;
  h1Title?: string;
  description?: string;
  image?: any;
  imageReplacement?: any;
  cta?: any;
  secondaryCta?: any;
  placeholder?: string;
  icon?: any;
  brandsList?: any;
  badge?: any;
  dataConsultModal?: any;
  page?: string;
  bgGradient?: string;
  thirdCta?: any;
  h1BadgeShadow?: boolean;
  affiliateModal?: any;
  titleClassName?: string;
  iconColor?: string;
}

const HeroSection: React.FC<HeroSectionProps> = props => {
  const {
    title,
    gradientTitle,
    gradientTitleVariant = HeaderVariant.h2,
    gradientPosition = 2,
    lineBreak = false,
    color = "purple",
    h1Title,
    cta,
    secondaryCta,
    image,
    imageReplacement,
    icon,
    brandsList,
    description,
    badge,
    page,
    dataConsultModal,
    bgGradient = "bg-gradient-to-b from-blue-1-gradient to-white",
    thirdCta,
    h1BadgeShadow = true,
    affiliateModal,
    titleClassName,
    iconColor
  } = props;

  return (
    <div className={`${bgGradient}`}>
      <NavigationBarSpacer />
      <Section className="sm:px-12 lg:px-4">
        <div
          className={cn(
            "pt-10 grid grid-cols-1 lg:grid-cols-2 items-center",
            page == PAGE.REFERRAL ? "sm:pt-28" : "sm:pt-16"
          )}
        >
          <div
            className={cn(
              "flex flex-col items-center md:items-start w-full mb-22 md:w-full lg:mb-0",
              page &&
                page === PAGE.PAGE_404 &&
                "justify-center order-2 lg:order-1"
            )}
          >
            {h1Title && (
              <Badge
                iconType={icon && icon}
                title={h1Title}
                h1BadgeShadow={h1BadgeShadow}
                iconColor={iconColor}
                className="mb-10 mx-auto md:mx-0"
                page={page}
              />
            )}

            {!title && gradientTitle ? (
              <div className={cn("max-w-xl", titleClassName && titleClassName)}>
                <GradientTitle
                  title={gradientTitle}
                  gradientPosition={gradientPosition}
                  color={color}
                  variant={gradientTitleVariant}
                  lineBreak={lineBreak}
                  className={cn("text-center md:text-left md:text-6xl")}
                />
              </div>
            ) : (
              <Header
                variant={HeaderVariant.h2}
                className="text-center md:text-left lg:text-6xl"
              >
                {title}
              </Header>
            )}
            <div
              className={cn(
                "text-center sm:text-left mt-4 text-xl text-gray-3-dark md:max-w-2xl ",
                page === PAGE.COMPETITOR &&
                  "stroketext-indigo text-transparent text-5xl stroketext uppercase tracking-[0.08em]",
                page === PAGE.AMAZON_PPC ? "lg:max-w-xl" : "lg:max-w-lg"
              )}
            >
              {description}
            </div>
            <div className="flex flex-col md:flex-row items-center w-full">
              {page === PAGE.BUSINESS_INTELLIGENCE ? (
                <div
                  className="text-indigo-1 cursor-pointer hover:text-indigo-2-light items-center font-semibold mt-4 w-full flex justify-center md:justify-start group"
                  onClick={() =>
                    dataConsultModal({ variant: "", isOpen: true })
                  }
                >
                  {cta?.text}{" "}
                  <Icon
                    className="ml-2 transition-all group-hover:ml-4"
                    iconType={IconType.rightArrow}
                  />
                </div>
              ) : (
                cta && (
                  <HeroCtas
                    cta={cta}
                    secondaryCta={secondaryCta}
                    thirdCta={thirdCta}
                    page={page}
                    dataConsultModal={dataConsultModal}
                    affiliateModal={affiliateModal}
                  />
                )
              )}
              {badge && badge}
            </div>
          </div>
          <div
            className={cn(
              "lg:flex items-center block",
              page && page == PAGE.PAGE_404 ? "order-1 lg:order-2" : ""
            )}
          >
            <div
              className={cn(
                "w-full lg:pl-12 text-center lg:block",
                page === PAGE.REFERRAL && "hidden"
              )}
            >
              {image && !imageReplacement ? (
                <GatsbyImage image={image} alt="" />
              ) : (
                imageReplacement
              )}
            </div>
          </div>
        </div>
        {brandsList && (
          <div className="mt-12">
            <Brands brandsList={brandsList} />
          </div>
        )}
      </Section>
    </div>
  );
};

export default HeroSection;
