import { navigate } from "gatsby";
import React from "react";
import { URL } from "../../constants/destinations";
import { useSegment, trackView } from "../../utils/analytics";
import {
  Category,
  Location,
  Name,
  Type,
  CTA_ID
} from "../../utils/analytics/constants";
import { nav } from "../../utils/navigation";
import Button, { ButtonSize, ButtonVariant } from "../Button";
import cn from "classnames";
import { PAGE } from "../../constants/pages";

interface HeroCTAProps {
  cta?: string;
  secondaryCta?: any;
  thirdCta?: any;
  customMessage?: string;
  page?: string;
  dataConsultModal?: any;
  affiliateModal?: any;
}

const HeroCtas: React.FC<HeroCTAProps> = (props: any) => {
  const { segment, helpers } = useSegment();
  const {
    cta,
    secondaryCta,
    thirdCta,
    customMessage,
    page,
    dataConsultModal,
    affiliateModal
  } = props;
  return (
    <>
      <div
        className={cn(
          "flex flex-col lg:flex-row items-center sm:items-start w-full mb-3 justify-center",
          thirdCta && page === PAGE.SELLICS_JOINS_PERPETUA ? "mt-6 lg:mt-10" : page === PAGE.AMAZON_SEO_TOOL ? "mt-0" : "mt-10",
          page !== PAGE.VIDEO_ADS && "lg:justify-start"
        )}
      >
        {/* Secondary CTA - first for some reason */}
        <div
          className={cn(
            "flex space-x-4",
            thirdCta && page === PAGE.SELLICS_JOINS_PERPETUA && "order-2 lg:order-1"
          )}
        >
          {secondaryCta?.text && (
            <Button
              id={CTA_ID.demoHeader.id}
              variant={
                secondaryCta?.disabled
                  ? ButtonVariant.disabled
                  : ButtonVariant.secondaryIndigo
              }
              size={ButtonSize.large}
              text={secondaryCta?.text}
              onClick={() => {
                if (page && page == PAGE.PAGE_404) {
                  navigate(-1);
                } else {
                  trackView(Name.Cta, {
                    category: Category.ButtonClick,
                    type: Type.Button,
                    text: secondaryCta?.text,
                    destination: secondaryCta?.route,
                    page_url: window?.location.href,
                    location: Location.Herobanner
                  });
                  !secondaryCta?.disabled && nav(secondaryCta?.route);
                }
              }}
            />
          )}
          {/* Primary CTA */}
          {cta?.text && (
            <Button
              id={cta?.ctaId ? cta?.ctaId : CTA_ID.getStartedHeader.id}
              variant={
                cta?.disabled
                  ? ButtonVariant.disabled
                  : customMessage == "amazon-self-serve"
                  ? ButtonVariant.secondaryIndigo
                  : ButtonVariant.primary
              }
              size={ButtonSize.large}
              text={cta?.text}
              onClick={() => {
                if (page === PAGE.AMC) {
                  dataConsultModal({ variant: "", isOpen: true });
                } else if (page === PAGE.REFERRAL) {
                  window.scrollTo({
                    top: 1500,
                    left: 0,
                    behavior: "smooth"
                  });
                } else if (page === PAGE.AFFILIATE) {
                  affiliateModal(true);
                } else {
                  helpers
                    .delayTrack(Name.Cta, {
                      category: Category.ButtonClick,
                      type: Type.Button,
                      text: cta?.text,
                      page_url: window?.location.href,
                      destination: `${URL.SignUp}`,
                      location: Location.Herobanner
                    })
                    .then(() => {
                      !cta?.disabled &&
                        customMessage != "amazon-self-serve" &&
                        nav(cta?.route);
                    });
                }
              }}
            />
          )}
        </div>
        {thirdCta && page === PAGE.SELLICS_JOINS_PERPETUA && (
          <div
            onClick={() => {
              nav(thirdCta?.route);
            }}
            className="justify-center cursor-pointer align-center mb-6 lg:mb-0 lg:ml-2 mt-4 md:mt-0 items-center py-3 px-0 lg:p-3 text-indigo-1 underline order-1"
          >
            {thirdCta?.icon}
            <p className="text-lg">{thirdCta?.text}</p>
          </div>
        )}
        {thirdCta && page === PAGE.PAGE_404 && (
          <div
            onClick={() => {
              nav(thirdCta?.route);
            }}
            className="flex cursor-pointer justify-center align-center bg-white rounded-3xl lg:ml-4 mr-0 mt-4 md:mt-0 font-semibold items-center px-6 py-3"
          >
            {thirdCta?.icon}
            <p className="md:ml-2 text-base">{thirdCta?.text}</p>
          </div>
        )}
      </div>
    </>
  );
};

export default HeroCtas;
