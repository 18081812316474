import cn from "classnames";
import React from "react";
import Header, { HeaderVariant } from "../Header";
import Icon from "../Icon";

interface SplitFeatureProps {
  features?: any;
  title?: string;
  image?: any;
  imageAlt?: string;
  imageReplacement?: any;
  reverse?: boolean;
}

const SplitFeature: React.FC<SplitFeatureProps> = props => {
  const {
    features,
    title,
    image,
    imageAlt,
    imageReplacement,
    reverse = false
  } = props;
  return (
    <div
      className={cn(
        "w-full flex flex-col justify-center items-center md:gap-12",
        reverse
          ? "flex-col-reverse lg:flex-row-reverse"
          : "flex-col-reverse lg:flex-row"
      )}
    >
      <div className="w-full lg:w-1/2 sm:pl-12 lg:pl-0">
        {title && (
          <Header className="sm:pr-12" variant={HeaderVariant.h3}>
            {title}
          </Header>
        )}
        {features && (
          <ul className="mt-8 max-w-xl">
            {features?.map(feature => {
              return (
                feature?.text && (
                  <li className="flex items-center mb-8 text-xl">
                    {feature?.icon && (
                      <div className="shadow-2xl rounded-full p-4 mr-6 bg-white">
                        <Icon iconType={feature.icon} />
                      </div>
                    )}
                    {feature?.text}
                  </li>
                )
              );
            })}
          </ul>
        )}
      </div>
      <div className="w-full lg:w-1/2 overflow-hidden text-center">
        {imageReplacement && imageReplacement}
      </div>
    </div>
  );
};

export default SplitFeature;
