import React, { useState } from "react";
import { useSegment, trackView } from "../../utils/analytics";
import Button, { ButtonSize, ButtonVariant } from "../Button";
import Icon, { IconType } from "../Icon";
import {
  Name,
  Type,
  Location,
  Category,
  CTA_ID
} from "../../utils/analytics/constants";
import { nav } from "../../utils/navigation";
import cn from "classnames";
import { useI18next } from "gatsby-plugin-react-i18next";
import { PAGE } from "../../constants/pages";

export enum PricingCardVariant {
  Starter = "starter",
  Growth = "growth",
  Pro = "pro",
  Enterprise = "enterprise",
  Premium = "premium"
}

interface PricingCardProps {
  variant: PricingCardVariant;
  onClick?: () => void;
  data?: any;
  className?: string;
  dataConsultModal?: any;
  page?: string;
}

const Divider: React.FC<{}> = () => (
  <div className="flex flex-row justify-center">
    <div className="w-10/12 border-t border-gray-5-light"></div>
  </div>
);

const PricingCard: React.FC<PricingCardProps> = props => {
  const { variant, onClick, data, className, dataConsultModal, page } = props;
  const { segment } = useSegment();
  const { language } = useI18next();

  return (
    <div
      className={cn("relative w-full bg-white rounded-lg shadow", className)}
    >
      <div className="flex flex-col">
        <div className="my-8 text-2xl font-semibold text-center text-gray-3-dark">
          {data?.displayedTitle}
        </div>
        <Divider />
        <div className="flex flex-row justify-center">
          <div
            className={cn(
              "text-lg font-semibold font-gray-3-dark text-center mt-8 mb-2 mr-2 inline-block items-center",
              page === PAGE.PRICING && language === "ja" && "min-h-[76px]"
            )}
          >
            <span className="text-4xl">{data?.price}</span>
            <span>
              {" "}
              {data?.pricePerMonth && "/"}
              {data?.pricePerMonth}
            </span>
          </div>
        </div>
        {data?.report && (
          <p className="text-center font-medium text-indigo-1 text-sm mt-2 uppercase">
            {data?.report}
          </p>
        )}
        {data?.notCostumer && (
          <div className="flex justify-center items-center">
            <p className="text-center text-sm text-gray-1">
              {data?.notCostumer}
            </p>
            <div className="ml-2 cursor-pointer group">
              <div className="text-white bg-gray-3-dark text-sm p-3 hidden group-hover:block rounded-lg max-w-[210px] top-[70px] right-[-15px] lg:right-[-35px] absolute before:bg-gray-3-dark before:bottom-[-5px] before:content-[''] before:w-[18px] before:absolute before:h-[13px] before:inset-x-1/2 before:rotate-45">
                <p className="mb-2 font-medium">{data?.discount?.title}</p>
                <p>{data?.discount?.text}</p>
              </div>
              <Icon iconType={IconType.alert} />
            </div>
          </div>
        )}
        {page === PAGE.PRICING &&
          language &&
          language === "ja" &&
          variant &&
          variant === PricingCardVariant.Premium && (
            <div>
              <div className="text-indigo-1 text-4xl text-center">+</div>
              <p className="text-center text-xl font-semibold">
                {" "}
                {data?.taxes}
              </p>
            </div>
          )}
        <div className="border-indigo-1 border-t w-4 mx-auto my-3" />
        {(page !== PAGE.PRICING || language !== "ja") && (
          <p className="text-center text-sm mt-2 mb-4 text-gray-1">
            {data?.taxes}
          </p>
        )}

        <div className="text-base text-gray-1 font-medium text-center">
          {data?.adSpent}
        </div>
        {data?.retailMediaPlatform && (
          <div className="text-base text-gray-1 font-medium text-center flex justify-center lg:flex-grow">
            {data?.retailMediaPlatform}
          </div>
        )}

        {data && data?.cta?.text && (
          <div className="flex flex-row justify-center mt-4 mb-8">
            <Button
              id={CTA_ID.demoMiddle.id}
              text={data && data?.cta?.text}
              variant={ButtonVariant.primary}
              size={ButtonSize.large}
              className={cn(
                "w-10/12 self-center",
                page === PAGE.BUSINESS_INTELLIGENCE && "!text-base"
              )}
              onClick={() => {
                trackView(Name.Cta, {
                  category: Category.ButtonClick,
                  type: Type.Button,
                  text: data?.cta?.text,
                  page_url: window?.location.href,
                  destination: data?.cta?.route,
                  location: `${Location.Pricing}_${variant}`
                });
                page && page === PAGE.BUSINESS_INTELLIGENCE
                  ? dataConsultModal({
                      variant:
                        data?.displayedTitle == "Bespoke Data Integrations" ||
                        data?.displayedTitle == "Custom Data Integrations"
                          ? "Data Integration"
                          : data?.displayedTitle == "Bespoke Report" ||
                            data?.displayedTitle == "Custom Reports"
                          ? "Custom Reporting"
                          : "",
                      isOpen: true
                    })
                  : page === PAGE.AMC
                  ? dataConsultModal({
                      variant: "Amazon Marketing Cloud (AMC)",
                      isOpen: true
                    })
                  : nav(data?.cta?.route);
              }}
            />
          </div>
        )}
      </div>

      {data?.pricingFeatures?.map((item, index) => {
        return (
          <>
            <div className="space-y-6 w-10/12 mb-7 mx-auto">
              <div className="flex flex-row">
                <div>
                  {item?.iconType[0] == "Plus" ? (
                    <Icon iconType={IconType.plus} />
                  ) : (
                    <Icon iconType={IconType.checkmark} />
                  )}
                </div>
                <div className="ml-4 flex flex-col justify-center text-lg text-gray-3-dark font-medium">
                  <span>{item?.text}</span>
                </div>
              </div>
            </div>
          </>
        );
      })}
      {page === PAGE.PRICING &&
        language &&
        language === "ja" &&
        variant &&
        variant === PricingCardVariant.Premium && (
          <div className="space-y-6 w-10/12 mb-7 mx-auto">
            <div className="flex flex-row">
              <div>
                <Icon iconType={IconType.plus} />
              </div>
              <div className="ml-4 flex flex-col justify-center text-lg text-gray-3-dark font-medium">
                <span>
                  {
                    "初期設定の構成提案、運用改善提案など、経験豊富なスタッフがフルサポート"
                  }
                </span>
              </div>
            </div>
          </div>
        )}
    </div>
  );
};

export default PricingCard;
