import React from "react";

interface GradientSectionProps {
  className?: string;
  id?: string;
}

const GradientSection: React.FC<
  React.PropsWithChildren<GradientSectionProps>
> = props => {
  const { children, className, id } = props;
  return (
    <div id={id} className={className}>
      <div className="max-w-8xl mx-auto px-6 md:px-4">{children}</div>
    </div>
  );
};

export default GradientSection;
